<template>
    <TemplateBase
        :theme="theme"
        :theme-variant="themeVariant"
    >
        <template #hero>
            <TheHero />
        </template>
        <DelayHydration>
            <template #default>
                <div>
                    <BaseSection data-width="page">
                        <h3>{{ entry.sectionText }}</h3>
                    </BaseSection>
                    
                    <BaseSection data-width="page">
                        <BaseCards :cards="entry.cards" />
                    </BaseSection>
                    
                    <BaseSection data-width="page">
                        <BaseCTA
                            :call-to-action="entry.callToAction?.[0]"
                        />
                    </BaseSection>
                    <BaseSection data-width="page">
                        <NewsletterCTA
                            :newsletter-cta="entry.newsletterCta?.[0]"
                        />
                    </BaseSection>
                </div>
            </template>
        </DelayHydration>
    </TemplateBase>
</template>

<script setup>
import HomeQuery from '~/graphql/queries/Home.graphql';

const { variables: defaultVariables }  = useCraftGraphql();

const variables = computed(() => {
    return {
        ...defaultVariables,
        // We don't need the URI for this query
        uri: null,
    };
});

const response = await useAsyncQuery({
    query: HomeQuery,
    variables
});
const { craftEntry: entry } = useCraftPage(response);

const theme = computed(() => entry.value?.selectedTheme);
const themeVariant = computed(() => entry.value?.themeVariant);
</script>

<style lang="less">

</style>

