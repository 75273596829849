<template>
    <header
        class="the-hero"
        :class="theme"
    >
        <div class="the-hero__container">
            <h1>
                <TheLogo class="the-hero_logo" />
            </h1>

            <div class="the-hero__content">
                <BaseImage
                    class="the-hero_image-desktop"
                    :image="themeImage"
                    fetchpriority="high"
                    loading="eager"
                />

                <div
                    :class="colorLastItem"
                    class="the-hero__info"
                >
                    <div>
                        <div
                            v-for="item in items"
                            :key="item"
                            class="the-hero__item"
                            :class="item.themeName"
                            @mouseover="changeThemeHero(item)"
                        >
                            <BaseImage
                                class="the-hero_image-mobile"
                                :image="item.image"
                                fetchpriority="high"
                                loading="eager"
                            />
                            <div class="the-hero__link">
                                <nuxt-link
                                    :to="item.url"
                                >
                                    <h2>{{ item.category }}</h2>
                                    <h3>{{ item.entryTitle }}</h3>
                                </nuxt-link>
                            </div>
                        </div>
                    </div>

                    <div class="the-hero__cta">
                        <BaseButton
                            v-for="cta in actions"
                            :key="cta.label"
                            element="a"
                            :href="cta.url"
                            class="the-hero__button"
                        >
                            {{ cta.label }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';

const items = ref([
    {
        category: 'Altijd te zien',
        entryTitle: 'Van Nijl tot Amstel',
        image: {
            url: 'https://allardpierson.nl/wp-content/uploads/2019/04/slider-img-03.jpg',
            width: '804',
            height: '705',
        },
        url: 'http://localhost:3000/test',
        themeName: 'theme-blue-brown',
    },
    {
        category: 'Nu te doen',
        entryTitle: 'De stem van… Activiteiten bij de ‘De roep van de o’o’',
        image: {
            url: 'https://allardpierson.nl/wp-content/uploads/2024/03/quagga-IZ.jpg',
            width: '804',
            height: '705',
        },
        url: 'http://localhost:3000/test',
        themeName: 'theme-pink-teal',
    },
    {
        category: 'Binnenkort, vanaf 31 aug',
        entryTitle: 'Johannes Nicolaas Helstone: Suriname in beweging',
        image: {
            url: 'https://allardpierson.nl/wp-content/uploads/2024/06/plaatje-voor-Uitmail.jpg',
            width: '804',
            height: '705',
        },
        url: 'http://localhost:3000/test',
        themeName: 'theme-green-purple',
    }
]);

const actions = ref([
    {
        label: 'Koop tickets',
        url: 'http://localhost:3000/test',
    },
    {
        label: 'Plan je bezoek',
        url: 'http://localhost:3000/test',
    },
]);

const props = defineProps({
    theme: {
        type: String,
        required: false,
        default: 'theme-blue-brown',
    },
    themeVariant: {
        type: String,
        required: false,
        default: 'theme-light',
    }
});

const { width } = useWindowSize();
console.log(width.value);

// FIX Hydration class mismatch error: now only works on save
const colorLastItem = computed(() => {
    return width.value < 768 ? items.value[items.value.length - 1].themeName : '';
});

const theme = ref(props.theme);

const themeImage = ref(items.value[0].image);

const changeThemeHero = ({ themeName, image }) => {
    theme.value = themeName;
    themeImage.value = image;
};

const head = computed(() => {
    return {
        bodyAttrs: {
            'data-theme': theme.value
        }
    };
});

useHead(head);
</script>

<style lang="less" src="./TheHero.less"></style>

